<template>
  <section>
    <b-table
      :data="isEmpty ? [] : listOrders"
      ref="table"
      paginated
      per-page="20"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :loading="isLoading"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      :show-detail-icon="showDetailIcon"
      @details-open="(row, index) => expended(row, index)"
    >
        <b-table-column field="id" label="ID" width="40" numeric sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="project" label="Project" sortable v-slot="props">
          <a @click="toggle(props.row)">
            {{ props.row.project }}
          </a> 
        </b-table-column>

        <b-table-column field="time" label="Order Time" sortable v-slot="props">
          {{ props.row.ordertime }}
        </b-table-column>

        <b-table-column field="name" label="Ordered By" sortable centered v-slot="props">
          {{ props.row.by_name }}
        </b-table-column>

        <b-table-column field="status" label="Status" sortable v-slot="props">
          <b-tag :type="props.row.status | isType" rounded>{{
            props.row.status | isStatus
          }}</b-tag>
        </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <div class="order-project">Project: {{ props.row.project }}</div>
              <div class="order-id">Order ID: {{ props.row.id }}</div>
              
              <div class="order-deliver mt-5">Deliver to:</div>
              <p>                
                <strong>Name: </strong>{{ props.row.name }} <br />
                <strong>Company: </strong>{{ props.row.company }} <br />
                <strong>Address: </strong>
                <span v-if="props.row.address">{{ props.row.address }}</span>
                <span v-if="props.row.optional">, {{ props.row.optional }}</span>
                <span v-if="props.row.city">, {{ props.row.city }}</span>
                <span v-if="props.row.state">, {{ props.row.state }}</span>
                <span v-if="props.row.country">, {{ props.row.country }}</span>
                <span v-if="props.row.postal">, {{ props.row.postal }}</span>
                <br />
                <strong v-if="props.row.comments">Comments:</strong>
                {{ props.row.comments }}
              </p>
            </div>
            <OrderItemsList :initems="props.row.iids" />
          </div>
        </article>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Items.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>
<script>
import OrderItemsList from "@/components/OrderItemsList.vue";

export default {
  name: "OrdersList",
  components: {
    OrderItemsList
  },
  data() {
    return {
      visibility: {},
      defaultOpenedDetails: [0],
      showDetailIcon: true,
      published: true,
      isEmpty: false,
      isLoading: true,
      listOrders: [],
      listOrderedItems: [],
      totalOrders: null
    };
  },
  methods: {
    loadData() {
      this.axios
        .post("data/manage_orders.php", {
          token: this.$store.getters.IS_JWT,
          action: "listOrders"
        })
        .then(response => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.listOrders = response.data.orders;

            this.totalOrders = this.listOrders.length;
            console.log("Total Result:" + this.listOrders.length);
          } else {
            this.hasItems = false;
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasItems = false;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    loadItems(iids) {
      this.listOrderedItems = [];
      this.axios
        .post("data/get_data.php", {
          token: this.$store.getters.IS_JWT,
          action: "listItemsIn",
          listIDS: iids
        })
        .then(response => {
          //console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.listOrderedItems = response.data.items;
            //his.totalRequests = this.listRequests.length;
            //console.log("Total Result:" + this.listRequests.length);
            // this.totalItems paginated
          } else {
            console.log("Error getting data");
          }
        })
        .catch(error => {
          console.log(error);
          //this.errored = true;
        });
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    expended(row, index) {
      console.log("iiids" + row.iids);
      this.loadItems(row.iids);
      this.defaultOpenedDetails = [row.id];
    },
    showDetails(item) {
      this.$store.commit("SET_ITEMDATA", item);
      this.$store.commit("SHOW_MODAL", { modal: "itemShow", state: true });
    }
  },
  mounted() {
    this.loadData();
  },
  filters: {
    isStatus: function(value) {
      if (!value) return "";
      if (value == "1") {
        return "Processing";
      } else if (value == "2") {
        return "Shipped / Away";
      } else if (value == "3") {
        return "Complete / Returned";
      } else if (value == "4") {
        return "Cancelled";
      } else if (value == "5") {
        return "Partially Returned";
      } else {
        return "Archived";
      }
    },
    isType: function(value) {
      if (!value) return "";
      if (value == "1") {
        return "is-warning";
      } else if (value == "2") {
        return "is-info";
      } else if (value == "3") {
        return "is-primary";
      } else if (value == "4") {
        return "is-danger";
      } else if (value == "5") {
        return "is-warning";
      } else {
        return "is-light";
      }
    }
  }
};
</script>
