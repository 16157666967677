<template>
  <div>
    <section class="hero is-light header">
      <div class="hero-body">
        <div class="container">
          <div class="header-title">Order History</div>   
          <div class="header-subtitle">List of all your orders</div>       
        </div>
      </div>
    </section>

    <section class="section mainsection">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <UserMenu activeElement="history" />
          </div>
          <div class="column">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Order History
                </p>
              </header>
              <div class="card-content">
                <OrdersList />
              </div>
            </div>
          </div>
        </div>
        <!-- end main column -->
      </div>
    </section>
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";
import OrdersList from "@/components/OrdersList.vue";

export default {
  name: "order-history",
  components: {
    UserMenu,
    OrdersList
  },
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: "Order History | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content: "History of all your orders."
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: "History of all your orders.",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  }, //end meta
  data() {
    return {
      somevar: null,
      umIsActive: [true, false, false, false]
    };
  },
  methods: {}
};
</script>
