<template>
  <section>
    <b-table
      :data="isEmpty ? [] : listItems"
      ref="table"
      :paginated="totalItems > 10 ? true : false"
      per-page="10"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :loading="isLoading"
    >

        <b-table-column field="id" label="ID" width="40" numeric sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="title" label="Title" sortable v-slot="props">
          <a @click="showDetails(props.row)">
            {{ props.row.title }}
          </a>
        </b-table-column>

        <b-table-column field="category" label="Category" sortable v-slot="props">
          {{getCategory(props.row.category)}}
        </b-table-column>
        <b-table-column label="Location" v-slot="props">
          <ItemLocation :islocation="props.row.location" />
        </b-table-column>


      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Items.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>
<script>

import { mapState } from "vuex";
import ItemLocation from "@/components/ItemLocation.vue";

export default {
  name: "OrderItemsList",
  components: {
    ItemLocation
  },
  props: {
    initems: String
  },
  data() {
    return {
      published: true,
      isEmpty: false,
      isLoading: true,
      listItems: [],
      totalItems: 0
    };
  },
  methods: {
    loadItems() {
      this.listItems = [];
      this.axios
        .post("data/get_data.php", {
          token: this.$store.getters.IS_JWT,
          action: "listItemsIn",
          listIDS: this.initems
        })
        .then(response => {
          //console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.listItems = response.data.items;
            this.totalItems = this.listItems.length;
            //console.log("Total Result:" + this.listRequests.length);
            // this.totalItems paginated
          } else {
            console.log("Error getting data");
          }
        })
        .catch(error => {
          console.log(error);
          //this.errored = true;
        });
    },
    showDetails(item) {
      this.$store.commit("SET_ITEMDATA", item);
      this.$store.commit("SHOW_MODAL", { modal: "itemShow", state: true });
    },
    getCategory(catID){
      //catID
      if(!this.categories){return "-";}
      let cat = this.categories.find(item => item.id === parseInt(catID));
        
      if(cat){
          return cat.name;
      }else{
          return "-";
      }     
    },
  },
  computed:{
    ...mapState(["categories"]),
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    initems: function() {
      this.loadItems();
    }
  }
};
</script>
